<template>
  <div class="wrap">我是主页</div>
</template>

<script>

export default {
  name: "home",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
